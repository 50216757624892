document.addEventListener("DOMContentLoaded", () => {
	if (document.querySelector("[data-mega-tab-path]")) {
		function showMegaTab(name) {
			document.querySelectorAll("[data-mega-tab]").forEach(megaTab => {
				megaTab.getAttribute("data-mega-tab") === name ? megaTab.classList.add("active") : megaTab.classList.remove("active");
			});
		}
		document.querySelectorAll("[data-mega-tab-path]").forEach(megaTabContainer => {
			const megaTabInput = megaTabContainer.querySelector("input[type=\"radio\"]"),
				megaTabName = megaTabContainer.getAttribute("data-mega-tab-path");
			megaTabInput.addEventListener("change", () => showMegaTab(megaTabName));
		});
	}
});