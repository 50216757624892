document.addEventListener("DOMContentLoaded", () => {

  function getRatingPercent(ratingZone, event) {
    const width = ratingZone.clientWidth,
      mouseX = event.offsetX;
    percent = Math.ceil((mouseX * 5) / width);
    return percent ? percent : 1;
  }

  document.querySelectorAll(".rating-wrapper_review").forEach(ratingReview => {
    const ratingInput = ratingReview.querySelector("input[type=\"hidden\"]"),
      ratingZone = ratingReview.querySelector(".rating"),
      ratingText = ratingReview.querySelector(".text"),
      ratingValueArray = [
        "— Очень плохо",
        "— Плохо",
        "— Нормально",
        "— Хорошо",
        "— Отлично"
      ];

    ratingZone.addEventListener("mouseover", event => {
      const rating = getRatingPercent(ratingZone, event);
      ratingZone.style.cssText = `--rating-percent: ${rating * 20}%`;
      ratingText.innerHTML = ratingValueArray[rating - 1];
    });

    ratingZone.addEventListener("mouseleave", () => {
      const ratingInputOldValue = ratingInput.value;
      ratingZone.style.cssText = `--rating-percent: ${ratingInputOldValue * 20}%`;
      ratingText.innerHTML = ratingValueArray[ratingInputOldValue - 1];
    });

    ratingZone.addEventListener("click", event => {
      ratingInput.value = getRatingPercent(ratingZone, event);
    });
  });
});
