document.addEventListener("DOMContentLoaded", () => {
	if (document.querySelector(".filter")) {
	    
	    if (window.innerWidth <= 1400) {
	        const mobileFilter = document.querySelector('.mobile-filter');
	        
	        mobileFilter.append(document.querySelector(".filter"));
	        
	        setTimeout(function(){
	            document.querySelector(".filter").removeAttribute('style');
	        }, 1500);
	    }
	    
		document.querySelectorAll(".filter").forEach(filter => {

			const filterCurrentList = filter.querySelectorAll(".filter-item__current");
			const filterDropdownMore = filter.querySelectorAll(".filter-item__more");
			
			filterCurrentList.forEach(current => {
				const currentContainer = current.closest(".filter-item");
				
				current.addEventListener("click", event => {
					event.preventDefault();
					currentContainer.classList.toggle("filter-item_active");
				});
			});

			filterDropdownMore.forEach(button => {
				const dropdownContainer = button.closest(".filter-dropdown__item_parent");

				button.addEventListener("click", event => {
					event.preventDefault();
					dropdownContainer.classList.toggle("filter-dropdown__item_show");
				});
			});
		});
	}

	$(document).on('mse2_load', function (e, data) {
		ScrollTrigger.refresh();
	});

});