function quickBuy(popupSelector, buttonSelector) {
    const popup = document.querySelector(popupSelector),
        popupTitle = popup.querySelector('.short-product__text'),
        popupImage = popup.querySelector('.short-product__image img'),
        popupCount = popup.querySelector('input[name="count"]'),
        popupProductId = popup.querySelector('input[name="productId"]'),
        //popupTagDiscount = popup.querySelectorAll('.short-product__tag-list .tag')[0],
        //popupTagPopular = popup.querySelectorAll('.short-product__tag-list .tag')[1],
        popupPrice = popup.querySelector('.price .new-price'),
        popupOldPrice = popup.querySelector('.price .old-price'),
        popupSumPrice = popup.querySelector('.sum-price'),
        popupInputs = popup.querySelectorAll('.text-input');
    
    document.addEventListener('click', (event) => {
        const target = event.target;
        
        if (target.closest(buttonSelector)) {
            
            popupInputs.forEach(item => {
                item.classList.remove('common-input_error');
            });
            
            const data = { id: target.closest(buttonSelector).getAttribute('data-product-id'), action: 'getOneJSONProduct' };
            
            fetch('/assets/elements/ajax/getOneJSONProduct.php', {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error(`Request failed. ${response.status}`);
                }
            })
            .then(data => {
                data = JSON.parse(data);
                
                let { id, pagetitle, price, old_price, thumb, popular, discount } = data;
                price = price.replace(/\s/g, '');
                old_price = old_price.replace(/\s/g, '');
            	    
                popupTitle.textContent = pagetitle;
                popupImage.src = thumb ? thumb : 'assets/templates/img/stubs/stub.jpg';
                popupProductId.value = id;
                if(popupCount) {
                    popupCount.value = 1;
                }
                popup.querySelector('.price').style.display = !Number(price) && !Number(old_price) ? 'none' : '';
                //popupTagPopular.style.display = Number(popular) ? '' : 'none';
                
                // if (Number(discount)) {
                //     popupTagDiscount.style.display = '';
                //     popupTagDiscount.textContent = `-${discount}%`;
                // } else {
                //     popupTagDiscount.style.display = 'none';
                //     popupTagDiscount.textContent = `0`;
                // }

                if (Number(price)) {
            	    popupPrice.parentNode.style.display = '';
            	    popupSumPrice.closest('.quick-buy__sum').style.display = '';
            	    
            	    popupPrice.textContent = miniShop2.Utils.formatPrice(price);
            	    popupPrice.setAttribute('data-price', price)
            	    popupSumPrice.textContent = miniShop2.Utils.formatPrice(price);
            	} else {
            	    popupPrice.parentNode.style.display = 'none';
            	    popupSumPrice.closest('.quick-buy__sum').style.display = 'none';
            	    
            	    popupPrice.textContent = 0;
            	    popupPrice.setAttribute('data-price', 0)
            	    popupSumPrice.textContent = 0;
            	}
            	
            	if (Number(old_price)) {
            	    popupOldPrice.parentNode.style.display = '';
            	    popupOldPrice.textContent = miniShop2.Utils.formatPrice(old_price);
            	    popupOldPrice.setAttribute('data-old-price', Number(old_price))
            	} else {
            	    popupOldPrice.parentNode.style.display = 'none';
            	    popupOldPrice.textContent = 0;
            	    popupOldPrice.setAttribute('data-old-price', 0)
            	}
                
            })
            .catch(error => {
                console.error(error);
            });
        }
        
        if (target.closest(`${popupSelector} [data-number-input-minus]`) || target.closest(`${popupSelector} [data-number-input-plus]`)) {
            setTimeout(function(){
                //popupPrice.textContent = miniShop2.Utils.formatPrice(popupPrice.getAttribute('data-price') * popupCount.value);
                //popupOldPrice.textContent = miniShop2.Utils.formatPrice(popupOldPrice.getAttribute('data-old-price') * popupCount.value);
                popupSumPrice.textContent = miniShop2.Utils.formatPrice(popupPrice.getAttribute('data-price') * popupCount.value);
            }, 100);
        }
    });

    if(popupCount) {
        popupCount.addEventListener('input', () => {
            setTimeout(function(){
                popupPrice.textContent = miniShop2.Utils.formatPrice(popupPrice.getAttribute('data-price') * popupCount.value);
                popupOldPrice.textContent = miniShop2.Utils.formatPrice(popupOldPrice.getAttribute('data-old-price') * popupCount.value);
                popupSumPrice.textContent = miniShop2.Utils.formatPrice(popupPrice.getAttribute('data-price') * popupCount.value);
            }, 100);
        });
    }
}

quickBuy('#quick-buy', '[data-quick-buy-button]');
quickBuy('#request-product', '[data-request-product-button]');
quickBuy('#request-services', '[data-request-service-button]');
quickBuy('#discontinued-product', '[data-discontinued-product-button]');