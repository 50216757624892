setTimeout(function(){
    document.querySelectorAll('input[name="message-key"]').forEach(item => {
       item.value = item.getAttribute('data-value');
    });
}, 5000);

document.addEventListener('fetchit:success', (event) => {
    const { response, form } = event.detail;
    
    Fancybox.close();
    if (form.closest('#quick-buy')) {
        Fancybox.show(
    		[{
    			src: "#order-successful",
    		}], {
    			Carousel: {
    				Panzoom: {
    					touch: false,
    				},
    			},
    		}
    	);
    }
    
    const fileInputs = form.querySelectorAll(".file-input");
   
    fileInputs.forEach(input => {
        const fileInput = input.querySelector("input[type=\"file\"]"),
            fileInputName = input.querySelector(".file-input__name");
            fileInputImages = input.previousElementSibling,
            fileInputError = input.querySelector("[data-error]");
            
        fileInputImages.style.display = "none";
        fileInputImages.innerHTML = "";
        fileInputName.textContent = "Прикрепить изображения";
        input.classList.remove("common-input_error");
        fileInputError.textContent = ``;
    });
});

document.addEventListener('fetchit:reset', (event) => {
    const { response, form } = event.detail;
    const messageKey = form.querySelector('input[name="message-key"]');
    
    setTimeout(() => {
        messageKey.value = messageKey.getAttribute('data-value');
    }, 1000);
});