$('.warning .warning_close').click(function(){
    closeWarning($(this).parent('.warning'));
});

$('.js-accept_cookies').click(function(){
    const data = {
        action: 'cookies_accepted',
    };
    
    setCookie('CookiesAccepted', 'accepted', {secure: true, 'max-age': 3600}); //1час
    
    closeWarning($(this).parents('.warning'));
    
});

function closeWarning(warning) {
    warning.hide();
}