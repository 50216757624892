function cartOpenPopup(status) {
    if (!status) return;
    if (!document.querySelector('#modal-cart').classList.contains('fancybox__content')) {
        Fancybox.show(
    		[{
    			src: "#modal-cart",
    		}], {
    			Carousel: {
    				Panzoom: {
    					touch: false,
    				},
    			},
    		}
    	);
    	
    	const crossSalePopup = document.querySelector('#modal-cart .short-product'),
    	    crossSalePopupTitle = crossSalePopup.querySelector('.short-product__text'),
    	    crossSalePopupImage = crossSalePopup.querySelector('.short-product__image img'),
    	    crossSalePopupKey = crossSalePopup.querySelector('.ms2_form input[name="key"]'),
    	    crossSalePopupCount = crossSalePopup.querySelector('.ms2_form input[name="count"]'),
    	    crossSalePopupPrice = crossSalePopup.querySelector('.ms2_cost'),
    	    crossSalePopupOldPrice = crossSalePopup.querySelector('.ms2_old_cost');
    	
    	crossSalePopupTitle.textContent = status.data.row.product_title;
        crossSalePopupImage.src = status.data.row.product_thumb ? status.data.row.product_thumb : 'assets/templates/img/stubs/stub.jpg';
        //crossSalePopup.id = status.data.row.key;
        crossSalePopupKey.value = status.data.row.key;
        crossSalePopupCount.value = status.data.row.count;
        crossSalePopup.querySelector('.price').style.display = !status.data.row.price && !status.data.row.old_price ? 'none' : '';
    	
    	if (status.data.row.price) {
    	    crossSalePopupPrice.parentNode.style.display = '';
    	    crossSalePopupPrice.textContent = miniShop2.Utils.formatPrice(status.data.row.price * status.data.row.count);
    	} else {
    	    crossSalePopupPrice.parentNode.style.display = 'none';
    	    crossSalePopupPrice.textContent = 0;
    	}
    	
    	if (status.data.row.old_price) {
    	    crossSalePopupOldPrice.parentNode.style.display = '';
    	    crossSalePopupOldPrice.textContent = miniShop2.Utils.formatPrice(status.data.row.old_price * status.data.row.count);
    	} else {
    	    crossSalePopupOldPrice.parentNode.style.display = 'none';
    	    crossSalePopupOldPrice.textContent = 0;
    	}
    	
    	const dataRelated = { id: status.data.row.id, action: 'getRelatedProducts' };
        
        fetch('/assets/elements/ajax/getRelatedProducts.php', {
            method: 'POST',
            body: JSON.stringify(dataRelated),
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error(`Request failed. ${response.status}`);
            }
        })
        .then(data => {
            document.querySelector('#modal-cart .modal-cart__additionally').innerHTML = data;
            buttonIconAnimation();
        })
        .catch(error => {
            console.error(error);
        });
    }
};

function inCart(status) {
    let inCartElems = document.querySelectorAll(`[data-in-cart='${status.data.row.id}']`);
    const dataInCart = { id: status.data.row.id, key: status.data.key, action: 'getInCart' };
    
    inCartElems.forEach(item => {
        item.classList.add('disabled');
        item.setAttribute('disabled', '');
    });
            
    fetch('/assets/elements/ajax/getInCart.php', {
        method: 'POST',
        body: JSON.stringify(dataInCart),
        headers: {
            'Content-Type': 'application/json',
        }
    })
    .then(response => {
        if (response.ok) {
            return response.json();
        } else {
            throw new Error(`Request failed. ${response.status}`);
        }
    })
    .then(data => {
        inCartElems.forEach(item => {
            item.outerHTML = data;
        });
    })
    .catch(error => {
        console.error(error);
    });
}

miniShop2.Callbacks.add('Cart.add.response.success', 'cart_add', function(status) {
    cartOpenPopup(status);
    inCart(status);
});

miniShop2.Callbacks.add('Cart.change.response.success', 'cart_change', function(status) {
    inCart(status);
});

miniShop2.Callbacks.add('Cart.remove.response.success', 'cart_remove', function(status) {
    inCart(status);
});

// document.addEventListener('click', (event) => {
//     const target = event.target;
//     const triggerInCartElem = target.closest('[data-trigger-in-cart-id]');
    
//     if (triggerInCartElem) {
//         const id = triggerInCartElem.getAttribute('data-trigger-in-cart-id');
//         const inCartElems = document.querySelectorAll(`[data-in-cart='${id}']`);
//         const inCartKey = inCartElems[0].querySelector('input[name="key"]').value;
//         const dataInCart = { id: id, key: inCartKey, action: 'getInCart' };
//         console.log(triggerInCartElem);
//         inCartElems.forEach(item => {
//             item.classList.add('disabled');
//             item.setAttribute('disabled', '');
//         });
                
//         fetch('/assets/elements/ajax/getInCart.php', {
//             method: 'POST',
//             body: JSON.stringify(dataInCart),
//             headers: {
//                 'Content-Type': 'application/json',
//             }
//         })
//         .then(response => {
//             if (response.ok) {
//                 return response.json();
//             } else {
//                 throw new Error(`Request failed. ${response.status}`);
//             }
//         })
//         .then(data => {
//             inCartElems.forEach(item => {
//                 item.outerHTML = data;
//             });
//         })
//         .catch(error => {
//             console.error(error);
//         });
//     }
// });