function buttonIconAnimation(selector = ".button.button_icon.animation") {
  if (!isMobile.any()) {
    document.querySelectorAll(selector).forEach(button => {
      ["mouseenter", "mouseout"].forEach(event => {
        button.addEventListener(event, (e) => {
          let parentOffset = button.getBoundingClientRect(),
            relY = e.clientY - parentOffset.top,
            relX = e.clientX - parentOffset.left;

          gsap.set(
            button,
            {
              "--before-top": relY + "px",
              "--before-left": relX + "px",
            }
          );

          if (event == "mouseenter") {
            gsap.fromTo(
              button,
              {
                "--before-scale": 0,
                "--before-size": "0%",
              },
              {
                "--before-scale": 3,
                "--before-size": "300%",
              }
            );
          } else if (event == "mouseout") {
            gsap.fromTo(
              button,
              {
                "--before-scale": 3,
                "--before-size": "300%",
              },
              {
                "--before-scale": 0,
                "--before-size": "0%",
              },
            );
          }
        });
      });
    });
  }
};