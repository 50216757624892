document.addEventListener("DOMContentLoaded", () => {
    
    document.addEventListener('click', function(event) {
        let target = event.target;
        
        if (target.closest('[data-number-input-minus]')) {
            const input = target.closest('[data-number-input]').querySelector('[data-number-value]');
            let minValue = input.getAttribute('min') ? input.getAttribute('min') : 0;
            
            if (input.value <= minValue) return;
            input.value = --input.value;
            input.closest('.ms2_form')?.querySelector('[name=ms2_action]').click();
        }
        
        if (target.closest('[data-number-input-plus]')) {
            const input = target.closest('[data-number-input]').querySelector('[data-number-value]');
            let maxValue = input.getAttribute('max') ? input.getAttribute('max') : 999;
            
            if (input.value >= maxValue) return;
            input.value = ++input.value;
            input.closest('.ms2_form')?.querySelector('[name=ms2_action]').click();
        }
    });
});
