Fancybox.bind("[data-fancybox]", {
	Thumbs: false,
	Toolbar: {
		display: {
			left: [],
			middle: [],
			right: ["close"],
		},
	},
	Carousel: {
		infinite: false,
	},
});
Fancybox.bind("[data-modal]", {
	autoFocus: false,
	Carousel: {
		Panzoom: {
			touch: false,
		},
	},
});
document.querySelectorAll("[data-modal]").forEach(button => {
	button.addEventListener("click", () => {
		Fancybox.close();
	});
});


const url = new URL(window.location.href);
if (url.searchParams.get('msorder')) {
    Fancybox.show(
		[{
			src: "#order-successful",
		}], {
			Carousel: {
				Panzoom: {
					touch: false,
				},
			},
		}
	);
}