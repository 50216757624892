document.addEventListener("DOMContentLoaded", () => {
    if (document.querySelector(".product-page")) {
        
        let productPageSwiper = new Swiper(".product-thumb__swiper", {
            navigation: {
                nextEl: ".product-thumb__next",
                prevEl: ".product-thumb__prev",
            },
            slidesPerView: 'auto',
            mousewheel: true,
            breakpoints: {
                1401: {
                    spaceBetween: 13,
                    direction: 'vertical',
                },
                631: {
                    spaceBetween: 13,
                    direction: 'vertical',
                },
                0: {
                    spaceBetween: 10,
                    direction: 'horizontal',
                }
            }
        });
        
        let productPageThumbs = new Swiper(".product-page__swiper", {
            effect: 'fade',
            fadeEffect: {
                crossFade: true
            },
            slidesPerView: 1,
            thumbs: {
                swiper: productPageSwiper,
            },
        });
    }
});
