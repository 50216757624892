function paginationFirstAnimation(selector = ".pagination") {
  if (document.querySelector(selector)) {
    document.querySelectorAll(selector).forEach(section => {
      sectionTL = gsap.timeline();

      section.querySelectorAll(".pagination__item").forEach((item, index) => {
        const delay = !index ? null : "-=0.10";
        sectionTL.fromTo(
          item,
          { opacity: 0, y: 10 },
          { opacity: 1, y: 0, duration: 0.2, ease: "cubicDefault" },
          delay
        );
      });

      if (section.querySelector(".pagination__button_prev")) {
        sectionTL.fromTo(
          section.querySelector(".pagination__button_prev"),
          { opacity: 0, x: -10 },
          { opacity: 1, x: 0, duration: 0.2, ease: "cubicDefault" }
        );
      }

      if (section.querySelector(".pagination__button_next")) {
        const delay = section.querySelector(".pagination__button_prev") ? "-=0.2" : null;
        sectionTL.fromTo(
          section.querySelector(".pagination__button_next"),
          { opacity: 0, x: 10 },
          { opacity: 1, x: 0, duration: 0.2, ease: "cubicDefault" },
          delay
        );
      }

      ScrollTrigger.create({
        animation: sectionTL,
        trigger: section,
        start: "top 80%",
        end: "bottom 100%",
        scrub: false,
      });

    });
  }
}
