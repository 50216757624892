function showDifferingCharacteristics() {
    const characteristicList = document.querySelectorAll('.comparison-page__content.active [data-characteristic]');
    
    characteristicList.forEach(item => {
        let characteristic = item.getAttribute('data-characteristic');
    
        let characteristicGroup = document.querySelectorAll(`.comparison-page__content.active [data-characteristic='${characteristic}']`);
        let characteristicString = item.querySelector('.comparison-info__value').textContent;
        let show = false;
        
        characteristicGroup.forEach(groupItem => {
            if (groupItem.querySelector('.comparison-info__value').textContent != characteristicString) {
                show = true;
                return false;
            }
        });

        if (show) {
            item.style.display = 'flex';
        } else {
            item.style.display = 'none';
        }
    });
}

document.addEventListener('click', event => {
    const target = event.target;
    
    let checkboxShowDifferences = target.closest('input[name="comparison_show_differences"]');
    
    if (checkboxShowDifferences) {
        if (checkboxShowDifferences.checked == true) {
            showDifferingCharacteristics();
        } else {
            document.querySelectorAll('.comparison-page__content.active [data-characteristic]').forEach(item => {
                item.style.display = 'flex';
            });
        }
    }
});