function headerFocus() {
    const header = document.querySelector('.header');
    if (window.innerWidth > 1400 && header) {
        
        let scrolltop = pageYOffset;
        
        window.addEventListener('scroll', function() {
            
            if (pageYOffset > scrolltop) {
                header.classList.remove('header_focus');
            } else if (pageYOffset < scrolltop) {
                header.classList.add('header_focus');
            }
            
            scrolltop = pageYOffset;
        });
        
        header.addEventListener("mouseover", (event) => {
            let target = event.target;
            if (target.closest('header')) {
                header.classList.add('header_focus');
            }
        });
    }
}

headerFocus();