document.addEventListener("DOMContentLoaded", () => {

  if (document.querySelector(".mobile-filter")) {
    const mobileFilterContainer = document.querySelector(".mobile-filter"),
      mobileFilterButtonList = document.querySelectorAll("[data-request-filter]"),
      mobileFilterCloseButton = mobileFilterContainer.querySelector(".mobile-filter__close"),
      mobileFilterResetButton = document.querySelectorAll("[data-filter-reset]");

    function showMobileFilter() {
      mobileFilterContainer.classList.add("mobile-filter_active");
      document.querySelector(".page__body").classList.add("disable-scroll");
      gsap.to(
        ".header",
        { y: -100 }
      );
    }

    function hiddenMobileFilter() {
      mobileFilterContainer.classList.remove("mobile-filter_active");
      document.querySelector(".page__body").classList.remove("disable-scroll");
      gsap.to(
        ".header",
        { y: 0 }
      );
    }

    mobileFilterButtonList.forEach(button => {
      button.addEventListener("click", event => {
        event.preventDefault();
        mobileFilterContainer.classList.contains("mobile-filter_active") ? hiddenMobileFilter() : showMobileFilter();
      });
    });

    mobileFilterCloseButton.addEventListener("click", event => {
      event.preventDefault();
      hiddenMobileFilter();
    });

    mobileFilterResetButton.forEach(filterReset => {
      filterReset.addEventListener("click", event => {
        event.preventDefault();
        hiddenMobileFilter();
      });
    });

    mobileFilterContainer.addEventListener("click", event => {
      if (!event.target.closest(".filter") && !event.target.closest(".mobile-filter__head")) {
        hiddenMobileFilter();
      }
    });
  }
});
