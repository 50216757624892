document.addEventListener("DOMContentLoaded", () => {
  const eliteMenuSelector = "[data-elite-menu]",
    eliteMenuPathSelector = "[data-elite-menu-path]",
    eliteMenuButtonSelector = "[data-elite-menu-button]",
    eliteMenuPanelSelector = "[data-elite-menu-panel]",
    eliteMenuAjaxContainerSelector = "[data-elite-ajax-container]";

  if (document.querySelector(eliteMenuSelector) && document.querySelector(eliteMenuPathSelector)) {
    const eliteMenuPanel = document.querySelector(eliteMenuPanelSelector),
      eliteMenuItemList = document.querySelectorAll(eliteMenuSelector);

    function clear(item) {
      return item.replace("[", "").replace("]", "");
    }

    function showEliteTab(eliteMenuItemSelector) {
      eliteMenuItemList.forEach(eliteMenuItem => {
        glowActiveCategory(eliteMenuItemSelector);
        eliteMenuItem.scrollTo({
          top: 0
        });
        if (eliteMenuItem.getAttribute(clear(eliteMenuSelector)) == eliteMenuItemSelector) {
          if (!document.querySelector(eliteMenuPanelSelector).classList.contains("elite-menu_show")) {
            showElitePanel(eliteMenuPanel, document.querySelector(eliteMenuButtonSelector));
          }
          eliteMenuItem.classList.add("elite-menu-tab_active");
        } else {
          eliteMenuItem.classList.remove("elite-menu-tab_active");
        }
      });
    }

    function glowActiveCategory(eliteMenuItemSelector) {
      document.querySelectorAll(eliteMenuPathSelector).forEach(eliteMenuPath => {
        if (eliteMenuPath.getAttribute(clear(eliteMenuPathSelector)) == eliteMenuItemSelector) {
          eliteMenuPath.classList.add("active");
        } else {
          eliteMenuPath.classList.remove("active");
        }
      });
    }

    function showElitePanel(eliteMenuPanel, eliteMenuButton) {
      const offsetLeft = eliteMenuButton.offsetLeft - eliteMenuButton.clientWidth;
      
      eliteMenuPanel.classList.add("elite-menu_show");
      eliteMenuButton.classList.add("active");
      eliteMenuPanel.style.setProperty("--menu-offset", `${offsetLeft / 16}rem`);
    }

    function hideElitePanel(eliteMenuPanel, eliteMenuButton) {
      eliteMenuPanel.classList.remove("elite-menu_show");
      eliteMenuButton.classList.remove("active");
    }
    
    function getPromotion(id, eliteMenuAjaxContainerSelector) {
        const request = { id: id, action: 'getCategoryPromotion' },
            eliteMenuAjaxContainer = document.querySelector(eliteMenuAjaxContainerSelector);
            
        eliteMenuAjaxContainer.style.opacity = 0.5;
        
        fetch('/assets/elements/ajax/getCategoryPromotion.php', {
            method: 'POST',
            body: JSON.stringify(request),
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error(`Request failed. ${response.status}`);
            }
        })
        .then(data => {
            
            const { html, type } = data;
            
            eliteMenuAjaxContainer.style.opacity = '';
            eliteMenuAjaxContainer.innerHTML = html;
            buttonIconAnimation();
            
            let eliteMenuSwiper = new Swiper("#elite-menu-swiper", {
                navigation: {
                    nextEl: '#elite-menu-next',
                    prevEl: '#elite-menu-prev',
                },
                effect: 'fade',
                fadeEffect: {
                    crossFade: true
                },
                mousewheel: {
                    releaseOnEdges: true,
                },
                loop: true,
                spaceBetween: 0,
                slidesPerView: 1,
                speed: 500,
            });
        })
        .catch(error => {
            eliteMenuAjaxContainer.style.opacity = '';
            eliteMenuAjaxContainer.innerHTML = '';
            console.error(error);
        });
    }

    document.querySelectorAll(eliteMenuButtonSelector).forEach(eliteMenuButton => {
      eliteMenuButton.addEventListener("click", event => {
        event.preventDefault();
        if (eliteMenuPanel.classList.contains("elite-menu_show")) {
          hideElitePanel(eliteMenuPanel, eliteMenuButton);
        } else {
          showElitePanel(eliteMenuPanel, eliteMenuButton);
        }
      });
    });

    document.querySelectorAll(eliteMenuPathSelector).forEach(eliteMenuPath => {
      eliteMenuPath.addEventListener("mouseenter", () => {
        const eliteMenuItemSelector = eliteMenuPath.getAttribute(clear(eliteMenuPathSelector));
        showEliteTab(eliteMenuItemSelector);
        getPromotion(eliteMenuPath.getAttribute("data-elite-category-id"), eliteMenuAjaxContainerSelector);
      });
    });

    window.addEventListener("mousemove", event => {
      if (
        !event.target.closest(eliteMenuSelector) &&
        !event.target.closest(eliteMenuPathSelector) &&
        !event.target.closest(eliteMenuButtonSelector) &&
        !event.target.closest(eliteMenuPanelSelector) &&
        !event.target.closest(".mobile-menu")
      ) {
        hideElitePanel(eliteMenuPanel, document.querySelector(eliteMenuButtonSelector));
      }
    });

  }
});
