document.querySelectorAll(".modal-action").forEach(modalAction => {
    modalAction.querySelectorAll(".tab").forEach(tabButton => {
        const tabRadio = tabButton.querySelector("input[type=\"radio\"]"),
        tabContent = modalAction.querySelector("[data-tab]");

        tabRadio.addEventListener("change", () => {
            tabContent.querySelector('input').setAttribute('placeholder', tabRadio.value);
            // tabContent.forEach(item => {
            //     item.classList.remove("text-input_active");

            //     if (item.getAttribute("data-tab") == tabRadio.value) {
            //         item.classList.add("text-input_active");
            //     }
            // });
        });
    });
});
