function advantagesFirstAnimation(selector = ".advantages.section") {
  if (document.querySelector(selector)) {
    document.querySelectorAll(selector).forEach(section => {
      sectionTL = gsap.timeline();

      if (section.querySelector(".advantages-card")) {
        section.querySelectorAll(".advantages-card").forEach((card, index) => {
          const delay = !index ? null : "-=0.15"; 
          sectionTL.fromTo(
            card,
            {opacity: 0, y: 10},
            {opacity: 1, y: 0, duration: 0.3, ease: "cubicDefault"},
            delay
          );
        });
      }

      if (section.querySelector(".advantages-icon")) {
        section.querySelectorAll(".advantages-icon").forEach((card, index) => {
          const delay = !index ? null : "-=0.15"; 
          sectionTL.fromTo(
            card,
            {opacity: 0, y: 10},
            {opacity: 1, y: 0, duration: 0.3, ease: "cubicDefault"},
            delay
          );
        });
      }

      ScrollTrigger.create({
        animation: sectionTL,
        trigger: section,
        start: "top 80%",
        end: "bottom 100%",
        scrub: false,
      });

    });
  }
}
