document.addEventListener("DOMContentLoaded", () => {

  function tooltipShow(element) {
    element.classList.add("tooltip_active");
  }

  function tooltipHide(element) {
    element.classList.remove("tooltip_active");
  }

  function tooltipHideAll() {
    document.querySelectorAll(".tooltip_active").forEach(element => {
      element.classList.remove("tooltip_active");
    });
  }

  document.querySelectorAll(".tooltip").forEach(tooltipContainer => {
    const tooltipButton = tooltipContainer.querySelector(".tooltip__button"),
      tooltipText = tooltipContainer.querySelector(".tooltip__text");

    Popper.createPopper(tooltipButton, tooltipText, {
      placement: 'top',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 7],
          },
        },
        {
          name: 'flip',
          options: {
            padding: 60,
          },
        },
      ],
    });

    tooltipButton.addEventListener("mouseenter", () => {
      tooltipShow(tooltipContainer);
    });

    tooltipButton.addEventListener("mouseleave", () => {
      tooltipHide(tooltipContainer);
    });

    document.addEventListener("click", event => {
      if (!event.target.closest(".tooltip")) {
        tooltipHideAll();
      }
    });
  });
});
