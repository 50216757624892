const notyf = new Notyf({
    ripple: false,
    position: {
        x: 'right',
        y: 'top'
    },
    types: [
    {
        type: 'success',
        background: 'var(--system-states-success)',
        className: 'text_body-small',
    },
    {
      type: 'error',
      background: 'var(--system-states-error)',
      className: 'text_body-small',
    },
    {
      type: 'info',
      background: 'var(--system-states-validation)',
      className: 'text_body-small',
    }
  ]
});

// document.body.prepend(document.querySelector('.notyf'));
// document.body.prepend(document.querySelector('.notyf-announcer'));

FetchIt.Message = {
    success(message) {
        notyf.success(message);
    },
    error(message) {
        notyf.error(message);
    },
};
