document.addEventListener("DOMContentLoaded", () => {
  if (document.querySelector(".header__search.search")) {
    const headerSearch = document.querySelector(".header__search.search"),
      eliteBackdrop = document.querySelector(".elite-backdrop");

    let lastOpenPosition = 0;
    
    function IsSafari() {
      var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
      console.log(isSafari);
      return isSafari;
    }

    document.querySelectorAll("[data-call-search]").forEach(searchButton => {
      searchButton.addEventListener("click", event => {
        event.preventDefault();
        lastOpenPosition = event.x;
        const searchButtonWidth = 20,
          searchGapWidth = 2,
          searchPadding = 7,
          containerOffset = 15,
          containerWidth = document.querySelector(".header-primary__container").clientWidth,
          searchInput = headerSearch.querySelector(".search__input.text-input");

        let inputWidth = containerWidth - (containerOffset * 2) - (searchPadding * 2) - searchGapWidth - searchButtonWidth;
        if (IsSafari()) {
          inputWidth = inputWidth - 20;
        }
        gsap.set(".header", { overflow: "hidden" });
        gsap.set(headerSearch, { gap: 0, visibility: "visible", left: lastOpenPosition, "z-index": 6 });
        gsap.set(searchInput, { width: 0 });
        gsap.to(searchInput, { width: inputWidth, duration: 0.6, ease: "cubicDefault" });
        gsap.to(headerSearch, { gap: searchGapWidth, opacity: 1, left: containerOffset, duration: 0.6, ease: "cubicDefault" });
        gsap.to(".header-primary__block.left", { opacity: 0, duration: 0.6, delay: 0.4, ease: "cubicDefault" });
        eliteBackdrop.classList.add("elite-backdrop_show");
      });
    });

    eliteBackdrop.addEventListener("click", event => {
      const searchInput = headerSearch.querySelector(".search__input.text-input");
      gsap.to(searchInput, { width: 0, duration: 0.6, ease: "cubicDefault" });
      gsap.to(headerSearch, { gap: 0, opacity: 0, left: lastOpenPosition - 20, duration: 0.6, ease: "cubicDefault" });
      gsap.to(".header-primary__block.left", {
        opacity: 1, duration: 0.6, ease: "cubicDefault", onComplete: () => {
          gsap.set(".header", { overflow: "" });
          gsap.set(headerSearch, { visibility: "hidden" });
        }
      });
      eliteBackdrop.classList.remove("elite-backdrop_show");
    });
  }
});
