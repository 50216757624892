document.addEventListener("DOMContentLoaded", () => {
  if (!isMobile.any()) {
    document.querySelectorAll(".button.button_tertiary").forEach(button => {

      if (button.closest(".header")) {
        button.classList.add("animation-ignore");
      }

      if (button.classList.contains("button_icon-right") && !button.classList.contains("animation-ignore")) {
        ["mouseenter", "mouseout"].forEach(event => {
          let iconWidth = 20 / 16 + "rem",
            iconWidthReverse = -20 / 16 + "rem",
            iconRightLeft = "calc(100% + 1.5625rem)";
          if (window.innerWidth > 1920) {
            iconWidth = "1.0416vw";
            iconWidthReverse = "-1.0416vw";
            iconRightLeft = "calc(100% + 1.302vw)"
          }
          button.addEventListener(event, (e) => {
            if (button.querySelector(".button__icon")) {
              const buttonIcon = button.querySelector(".button__icon"),
                buttonContent = button.querySelector(".button__content");
              if (event == "mouseenter") {
                gsap.to(
                  buttonIcon,
                  {
                    opacity: 0,
                    duration: 0.6, ease: "cubicDefault",
                    onComplete: () => {
                      gsap.set(
                        buttonIcon,
                        { right: iconRightLeft, x: 0 }
                      );
                      gsap.to(
                        buttonIcon,
                        { x: iconWidth, opacity: 1, duration: 0.6, ease: "cubicDefault" }
                      );
                    }
                  }
                );
                gsap.to(
                  buttonContent,
                  { x: iconWidth, duration: 0.6, ease: "cubicDefault" }
                );
              } else if (event == "mouseout") {
                gsap.to(
                  buttonIcon,
                  {
                    x: iconWidth,
                    opacity: 0,
                    duration: 0.6, ease: "cubicDefault",
                    onComplete: () => {
                      gsap.set(
                        buttonIcon,
                        { right: "0", x: iconWidth }
                      );
                      gsap.to(
                        buttonIcon,
                        { x: 0, opacity: 1, duration: 0.6, ease: "cubicDefault" }
                      );
                    }
                  }
                );
                gsap.to(
                  buttonContent,
                  { x: 0, duration: 0.6, ease: "cubicDefault" }
                );
              }
            }
          });
        });
      }

      if (button.classList.contains("button_icon-left") && !button.classList.contains("animation-ignore")) {
        ["mouseenter", "mouseout"].forEach(event => {
          button.addEventListener(event, (e) => {
            if (button.querySelector(".button__icon")) {
              const buttonIcon = button.querySelector(".button__icon"),
                buttonContent = button.querySelector(".button__content");
              if (event == "mouseenter") {
                gsap.to(
                  buttonIcon,
                  {
                    opacity: 0,
                    duration: 0.6, ease: "cubicDefault",
                    onComplete: () => {
                      gsap.set(
                        buttonIcon,
                        { left: iconRightLeft, x: 0 }
                      );
                      gsap.to(
                        buttonIcon,
                        { x: -iconWidthReverse, opacity: 1, duration: 0.6, ease: "cubicDefault" }
                      );
                    }
                  }
                );
                gsap.to(
                  buttonContent,
                  { x: -iconWidthReverse, duration: 0.6, ease: "cubicDefault" }
                );
              } else if (event == "mouseout") {
                gsap.to(
                  buttonIcon,
                  {
                    opacity: 0,
                    duration: 0.6, ease: "cubicDefault",
                    onComplete: () => {
                      gsap.set(
                        buttonIcon,
                        { left: "0", x: -iconWidthReverse }
                      );
                      gsap.to(
                        buttonIcon,
                        { x: 0, opacity: 1, duration: 0.6, ease: "cubicDefault" }
                      );
                    }
                  }
                );
                gsap.to(
                  buttonContent,
                  { x: 0, duration: 0.6, ease: "cubicDefault" }
                );
              }
            }
          });
        });
      }

    });
  }
});
