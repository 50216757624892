const headerNav = document.querySelector(".header-secondary__nav.nav"),
  headerNavItemList = headerNav.querySelectorAll(".nav__item"),
  headerMore = headerNav.querySelector(".header-more"),
  headerMoreContainer = headerMore.querySelector(".select-input__answer"),
  headerNavItemLimit = 7;

// function movedElementFromNavigation(index) {
//   const navItem = headerNavItemList[index],
//     navLink = navItem.querySelector("a");

//   navItem.style.display = "none";
//   headerMoreContainer.innerHTML += `<a class="select-answer__item" href="${navLink.href}"><span class="select-answer__text">${navLink.innerHTML}</span></a>`;
// }

// document.addEventListener("DOMContentLoaded", () => {
//   if (headerNav.scrollWidth > headerNav.offsetWidth) {
//     let index = headerNavItemList.length;
//     while (headerNav.scrollWidth > headerNav.offsetWidth) {
//       --index; movedElementFromNavigation(index);
//     }
//     setInterval(() => {
//       while (headerNav.scrollWidth > headerNav.offsetWidth) {
//         --index; movedElementFromNavigation(index);
//       }
//     }, 1000);
//   } else {
//     headerMore.classList.remove("header-more_show");
//   }
// });


if (headerNavItemList.length > headerNavItemLimit) {
  headerMore.classList.add("header-more_show")
  headerNavItemList.forEach((navItem, index) => {
    const navLink = navItem.querySelector("a");
    if (index > headerNavItemLimit - 1) {
      navItem.style.display = "none";
      headerMoreContainer.innerHTML += `<a class="select-answer__item" href="${navLink.href}"><span class="select-answer__text">${navLink.innerHTML}</span></a>`;
    }
  });
}
