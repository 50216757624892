document.addEventListener("DOMContentLoaded", () => {
  if (document.querySelector("[data-filter-sort]")) {

    function showFilterSort(container) {
      container.classList.add("filter-sort_active");
    }

    function hiddenFilterSort(container) {
      container.classList.remove("filter-sort_active");
    }

    function hiddenAllFilterSort() {
      document.querySelectorAll(".filter-sort_active").forEach(filterSortContainer => {
        filterSortContainer.classList.remove("filter-sort_active");
      });
    }

    document.querySelectorAll("[data-filter-sort]").forEach(filterSortContainer => {
      const filterSortCurrent = filterSortContainer.querySelector(".filter-sort__current"),
        filterSortCurrentText = filterSortContainer.querySelector(".filter-sort__text"),
        filterSortItemList = filterSortContainer.querySelectorAll(".filter-sort__item");

      filterSortCurrent.addEventListener("click", event => {
        event.preventDefault();
        filterSortContainer.classList.contains("filter-sort_active") ? hiddenFilterSort(filterSortContainer) : showFilterSort(filterSortContainer);
      });

      filterSortItemList.forEach(item => {
        item.addEventListener("click", event => {
          event.preventDefault();
          hiddenAllFilterSort();
          //filterSortCurrentText.innerHTML = item.innerHTML;
        });
      });
    });

    document.addEventListener("click", event => {
      if (!event.target.closest("[data-filter-sort]")) {
        hiddenAllFilterSort();
      }
    });
  }
});
