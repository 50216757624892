function breadcrumbsFirstAnimation(selector = ".breadcrumbs") {
  if (document.querySelector(selector)) {
    document.querySelectorAll(selector).forEach(section => {
      sectionTL = gsap.timeline();

      section.querySelectorAll(".breadcrumbs__item").forEach((item, index) => {
        const delay = !index ? null : "-=0.10";
        sectionTL.fromTo(
          item,
          { opacity: 0, x: 30 },
          { opacity: 1, x: 0, duration: 0.3, ease: "cubicDefault" },
          delay
        );
      });

      ScrollTrigger.create({
        animation: sectionTL,
        trigger: section,
        start: "top 80%",
        end: "bottom 100%",
        scrub: false,
      });

    });
  }
}
