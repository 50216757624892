if (document.querySelector(".file-input")) {

    function fileIsUpload(fileInputContainer, fileInputOldName) {
        const fileInput = fileInputContainer.querySelector("input[type=\"file\"]"),
        fileInputName = fileInputContainer.querySelector(".file-input__name");
        fileInputImages = fileInputContainer.previousElementSibling,
        fileInputError = fileInputContainer.querySelector("[data-error]");
        maxFileSize = 4096 * 1024,
        maxFilesLength = 5,
        allowedFileType = fileInput.getAttribute("accept").replace(/\s/g, "").split(",");
        
        fileInputImages.style.display = "none";
        fileInputImages.innerHTML = "";
        
        function validationMessage(message) {
            fileInput.value = '';
            fileInputImages.style.display = "none";
            fileInputImages.innerHTML = "";
            fileInputContainer.classList.add("common-input_error");
            fileInputError.textContent = message;
        };
        
        if (fileInput.files.length > maxFilesLength) {
            validationMessage(`Максимальное количество изображений для загрузки — ${maxFilesLength}`)
        }
        
        [...fileInput.files].forEach(file => {
            
            if (!allowedFileType.includes(file.type)) {
                validationMessage(`Файл ${file.name} имеет не разрешённый тип.`);
                return;
            }
            
            if (file.size > maxFileSize) {
                validationMessage(`Размер файла ${file.name} превышает 4 Мб.`);
                return;
            }
            
            fileInputImages.style.display = "";
            fileInputContainer.classList.remove("common-input_error");
            fileInputError.textContent = ``;
            
            const loadImg = document.createElement('a');
            loadImg.href = URL.createObjectURL(file);
            loadImg.setAttribute("class", "zoom-image zoom-image_small zoom-image_scale");
            loadImg.setAttribute("data-fancybox", "loadImages");
            loadImg.setAttribute("data-caption", file.name);
            loadImg.innerHTML  = `<img src="${URL.createObjectURL(file)}" width="80" height="80">`;
            
            fileInputImages.append(loadImg);
        });
    
        if (fileInput.files.length == 1) {
            fileInputName.innerHTML = fileInput.files[0].name.trim();
        } else if (fileInput.files.length > 1) {
            fileInputName.innerHTML = `${fileInput.files.length} ${toCaseCount(fileInput.files.length)}`;
        } else {
            fileInputName.innerHTML = fileInputOldName;
        }
    }

    document.querySelectorAll(".file-input").forEach(fileInputContainer => {
        const fileInput = fileInputContainer.querySelector("input[type=\"file\"]"),
        fileInputOldName = fileInputContainer.querySelector(".file-input__name").innerHTML.trim();
        fileInput.addEventListener("change", (event) => {
            fileIsUpload(fileInputContainer, fileInputOldName);
        });
    });
}