function ctaFirstAnimation(selector = ".cta.section") {
  if (document.querySelector(selector)) {
    document.querySelectorAll(selector).forEach(section => {
      sectionTL = gsap.timeline();

      if (section.querySelector(".cta__image>picture")) {
        gsap.set(
          section.querySelector(".cta__image>picture"),
          {"transform-origin": "50% 100%"}
        );
        sectionTL.fromTo(
          section.querySelector(".cta__image>picture"),
          {opacity: 0, scale: 0.4},
          {opacity: 1, scale: 1, duration: 0.5, ease: "cubicDefault"},
        );
      }

      if (section.querySelector(".cta__title")) {
        sectionTL.fromTo(
          section.querySelector(".cta__title"),
          {opacity: 0, y: 10},
          {opacity: 1, y: 0, duration: 0.5, ease: "cubicDefault"},
          "-=0.3"
        );
      }

      if (section.querySelector(".cta__text")) {
        sectionTL.fromTo(
          section.querySelector(".cta__text"),
          {opacity: 0, y: 10},
          {opacity: 1, y: 0, duration: 0.5, ease: "cubicDefault"},
          "-=0.3"
        );
      }

      if (section.querySelector(".cta__form")) {
        sectionTL.fromTo(
          section.querySelector(".cta__form"),
          {opacity: 0},
          {opacity: 1, duration: 0.5, ease: "cubicDefault"},
          "-=0.3"
        );
      }

      ScrollTrigger.create({
        animation: sectionTL,
        trigger: section,
        start: "top 80%",
        end: "bottom 100%",
        scrub: false,
      });

    });
  }
}
