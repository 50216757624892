function actionsPageFirstAnimation(selector = ".actions-page.section") {
  if (document.querySelector(selector)) {
    document.querySelectorAll(selector).forEach(section => {
      sectionTL = gsap.timeline();

      section.querySelectorAll(".actions__item").forEach(card => {
        sectionTL.fromTo(
          card,
          { opacity: 0, y: -10 },
          { opacity: 1, y: 0, duration: 0.25, ease: "cubicDefault" },
          "-=0.16"
        )
      });

      ScrollTrigger.create({
        animation: sectionTL,
        trigger: section,
        start: "top 80%",
        end: "bottom 100%",
        scrub: false,
      });

    });
  }
}
