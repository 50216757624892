document.addEventListener("DOMContentLoaded", () => {
  document.querySelectorAll(".review-card_long").forEach(card => {
    const reviewMoreButton = card.querySelector(".review-card__more"),
      reviewModalContent = document.querySelector(".review-modal");
    reviewMoreButton.addEventListener("click", event => {
      event.preventDefault();
      reviewModalContent.innerHTML = "";
      reviewModalContent.append(card.cloneNode(true));
      Fancybox.show(
        [{
          src: "#review-modal",
        }],
        {
          Carousel: {
            Panzoom: {
              touch: false,
            },
          },
        }
      );
    });
  });
});

