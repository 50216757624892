function reviewPageFirstAnimation(selector = ".review-page") {
  if (document.querySelector(selector)) {
    document.querySelectorAll(selector).forEach(section => {
      sectionTL = gsap.timeline();

      if (section.querySelector(".review-page__feedback")) {
        sectionTL.fromTo(
          section.querySelector(".review-page__feedback"),
          { opacity: 0, x: 15 },
          { opacity: 1, x: 0, duration: 0.4, ease: "cubicDefault" }
        );
      }

      section.querySelectorAll(".review-card").forEach((card, index) => {
        const delay = !index ? null : "-=0.10";
        sectionTL.fromTo(
          card,
          { opacity: 0, y: 10 },
          { opacity: 1, y: 0, duration: 0.2, ease: "cubicDefault" },
          delay
        );
      });

      ScrollTrigger.create({
        animation: sectionTL,
        trigger: section,
        start: "top 80%",
        end: "bottom 100%",
        scrub: false,
      });

    });
  }
}
