document.addEventListener("DOMContentLoaded", () => {
    
    const msfavoritesContainer = document.querySelector('[data-msfavorites-container]');
    
    msfavoritesContainer?.addEventListener('click', event => {
        const target = event.target;
        
        if (target.closest('.msfavorites.voted')) {
            target.closest('.product-card').remove();
            
            let productsLength = msfavoritesContainer.querySelectorAll('.product-card').length;
            
            if (productsLength == 0) {
                setTimeout(function(){
                    location.reload();
                }, 100);
            }
        }
    });
});