var mobileBreakPoints = 600;

/*try {
    new basictable(".table>table", {
        breakpoint: mobileBreakPoints,
        forceResponsive: true,
        contentWrap: true,
    });
} catch (e) {
    console.error(e);
}

try {
    new basictable(".article table", {
        breakpoint: mobileBreakPoints,
        forceResponsive: true,
        contentWrap: true,
        tableWrap: true,
    });
} catch (e) {
    console.error(e);
}*/

$(function() {
    $(".article table").wrap('<div class="table-wrapper"></div>')
})

function toCaseCount(number) {
  let titles = ['файл', 'файла', 'файлов'];
  let cases = [2, 0, 1, 1, 1, 2];
  return titles[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[Math.min(number % 10, 5)]];
}

function getCookie(name) {
  let matches = document.cookie.match(new RegExp(
    "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
  ));
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

function setCookie(name, value, options = {}) {

  options = {
    path: '/',
    // при необходимости добавьте другие значения по умолчанию
    ...options
  };

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  }

  let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

  for (let optionKey in options) {
    updatedCookie += "; " + optionKey;
    let optionValue = options[optionKey];
    if (optionValue !== true) {
      updatedCookie += "=" + optionValue;
    }
  }

  document.cookie = updatedCookie;
}

function deleteCookie(name) {
  setCookie(name, "", {
    'max-age': -1
  })
}

document.addEventListener('click', (event) => {
    const target = event.target;
    
    if (target.closest('[data-city]')) {
        event.preventDefault();
        
        setCookie('city', target.closest('[data-city]').getAttribute('data-city'));
        location.reload();
    }
});

document.addEventListener('input', event => {
    const target = event.target;

    if (target.matches('input[name="name"]') || target.matches('input[name="receiver"]')) {
        target.value = target.value.replace(/[^A-Za-zА-Яа-я\-\s]/g, '');
    }
});



function toTopButton() {
    const buttonToTop = document.querySelector('[data-scroll-top]');

    const heightLimit = 1000;

    if (window.pageYOffset >= heightLimit) {buttonToTop.classList.add('to-top_visible');}

    window.addEventListener('scroll', function() {

        if (window.pageYOffset >= heightLimit) {
            buttonToTop.classList.add('to-top_visible');
        } else if (window.pageYOffset < heightLimit) {
            buttonToTop.classList.remove('to-top_visible');
        }

    });
};

toTopButton();

function scrollToElement(element) {
    const getHeaderHeight = function(element) {
        return document.querySelector('.header').clientHeight;
    }

    let elemCoords;
    let top;

    if (!element) {
        top = 0;
    } else {
        elemCoords = element.getBoundingClientRect();
        top = elemCoords.top + pageYOffset - getHeaderHeight();
    }

    window.scrollTo({
        top: top,
        behavior: "smooth"
    });
};

document.addEventListener('click', function(event) {
    let target = event.target;

    if(target.closest('[data-scroll-top]')) {
        event.preventDefault();
        scrollToElement();
    }
});

if(!!window.IntersectionObserver){
    // устанавливаем настройки
    const options = {
        root: null,
        rootMargin: '0px',
        threshold: 0.2,
    }

    // создаем наблюдатель
    const observerFooter = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {

            const whatsappButton = document.querySelector("[data-whatsapp-scroll]");
            const topButton = document.querySelector("[data-scroll-top]");
            const warningCookie = document.querySelector("[data-warning-cookie]");

            if (entry.isIntersecting) {
                whatsappButton.classList.add("whatsapp-button_up");
                topButton.classList.add("scroll-button_up");
                if (warningCookie) warningCookie.classList.add("cookie_scroll_up");
            } else {
                whatsappButton.classList.remove("whatsapp-button_up");
                topButton.classList.remove("scroll-button_up");
                if (warningCookie) warningCookie.classList.remove("cookie_scroll_up");
            }
        });
    }, options);

    observerFooter.observe(document.querySelector("[data-whatsapp-scroll-trigger]"));
}