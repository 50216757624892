document.addEventListener("DOMContentLoaded", () => {
    
    const checkRadio = function(radioButton) {
    
        document.querySelectorAll('[data-delivery]').forEach(function(item){
            item.classList.remove('active');
        });
        
        const tabs = document.querySelectorAll('[data-delivery]');
        
        tabs.forEach(item => {
            let itemDataDeliveryArr = item.getAttribute('data-delivery').split(',');
            
            if (itemDataDeliveryArr.includes(radioButton)) {
                item.classList.add('active');
            }
        });
    }
    
    if (document.querySelector("[name='delivery'][checked]")) {
        
        let checkedRadioButton = document.querySelector("input[name='delivery'][checked]").value;
        
        checkRadio(checkedRadioButton);
    }
    
    document.addEventListener('click', function(event){
        let target = event.target;
    
        if (target.closest("input[name='delivery']")) {
            let radioValue = target.closest("input[name='delivery']").value;

            checkRadio(radioValue);
        }
    });
});
