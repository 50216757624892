function photogalleryPageFirstAnimation(selector = ".photogallery-page") {
  if (document.querySelector(selector)) {
    document.querySelectorAll(selector).forEach(section => {
      sectionTL = gsap.timeline();

      section.querySelectorAll(".zoom-image").forEach((card, index) => {
        const delay = !index ? null : "-=0.1"; 
        sectionTL.fromTo(
          card,
          {opacity: 0, y: 10},
          {opacity: 1, y: 0, duration: 0.2, ease: "cubicDefault"},
          delay
        );
      });

      ScrollTrigger.create({
        animation: sectionTL,
        trigger: section,
        start: "top 80%",
        end: "bottom 100%",
        scrub: false,
      });

    });
  }
}
