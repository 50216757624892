function productFirstAnimation(selector = ".products.section") {
  if (document.querySelector(selector)) {
    document.querySelectorAll(selector).forEach(section => {
      sectionTL = gsap.timeline();

      section.querySelectorAll(".swiper-slide").forEach((card, index) => {
        const delay = !index ? null : "-=0.15"; 
        sectionTL.fromTo(
          card,
          {opacity: 0, y: 10},
          {opacity: 1, y: 0, duration: 0.3, ease: "cubicDefault"},
          delay
        );
      });

      ScrollTrigger.create({
        animation: sectionTL,
        trigger: section,
        start: "top 80%",
        end: "bottom 100%",
        scrub: false,
      });

    });
  }
}
