document.addEventListener("DOMContentLoaded", () => {
  let containerOffset = window.getComputedStyle(document.querySelector(".container"), null);
  containerOffset = containerOffset.getPropertyValue('padding-right').trim();
  containerOffset = Number(containerOffset.replace("px", ""));

  gsap.registerPlugin(ScrollTrigger, CustomEase);

  CustomEase.create("cubicDefault", ".25,.1,.25,1");

  function currentGSAP() {
    buttonIconAnimation();
    sectionFirstAnimation();
    catalogFirstAnimation();
    catalogPageFirstAnimation();
    actionsFirstAnimation();
    actionsPageFirstAnimation();
    productFirstAnimation();
    productFirstAnimation(".services.section");
    advantagesFirstAnimation();
    ctaFirstAnimation();
    reviewFirstAnimation();
    reviewPageFirstAnimation();
    photogalleryFirstAnimation();
    photogalleryPageFirstAnimation();
    certificatesFirstAnimation();
    newsFirstAnimation();
    newsPageFirstAnimation();
    servicesPageFirstAnimation();
    paginationFirstAnimation();
    contactsPageFirstAnimation();
    searchPageFirstAnimation();
    wishlistPageFirstAnimation();
    breadcrumbsFirstAnimation();
  }

  bodyFirstAnimation(".page__body");

  currentGSAP();
});
