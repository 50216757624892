document.addEventListener("DOMContentLoaded", () => {
  if (document.querySelector(".dropdown")) {
    document.querySelectorAll(".dropdown").forEach(dropdown => {
      dropdown.querySelectorAll(".dropdown__item").forEach(dropdownItem => {
        const dropdownCurrent = dropdownItem.querySelector(".dropdown__current"),
          dropdownCheckbox = dropdownItem.querySelector(".dropdown__checkbox"),
          dropdownContent = dropdownItem.querySelector(".dropdown__content"),
          dropdownArticle = dropdownContent.querySelector(".article");

        dropdownCurrent.addEventListener("click", event => {
          event.preventDefault();

          dropdown.querySelectorAll(".dropdown__item").forEach(item => {
            if (item.querySelector(".dropdown__current") !== dropdownCurrent) {
              const itemInput = item.querySelector("input[type=\"checkbox\"]");
              if (itemInput.checked) {
                itemInput.checked = false;
                item.querySelector(".dropdown__content").style.height = "";
              }
            }
          });

          if (dropdownCheckbox.checked) {
            dropdownCheckbox.checked = false;
            dropdownContent.style.height = "";
          } else {
            dropdownCheckbox.checked = true;
            dropdownContent.style.height = `${dropdownArticle.clientHeight / 16}rem`;
          }

        });

        if (dropdownCheckbox.checked) {
          dropdownContent.style.height = `${dropdownArticle.clientHeight / 16}rem`;
        }

      });
    });
  }
});
