document.addEventListener("DOMContentLoaded", () => {

  if (document.querySelector(".ya-map"))
    document.querySelectorAll(".ya-map").forEach(mapContainer => {
      function yaLoadMap() {
        const mapCoordinates = mapContainer.getAttribute("data-ya-map-coordinates").split(','),
          apikey =  mapContainer.getAttribute("data-ya-map-api-key"),
          mapZoom = mapContainer.getAttribute("data-ya-map-zoom"),
          hintContent = mapContainer.getAttribute("data-ya-map-hintContent"),
          balloonContent = mapContainer.getAttribute("data-ya-map-balloonContent"),
          iconImageHref = mapContainer.getAttribute("data-ya-map-iconImageHref");

        if (mapContainer) {
          const script = document.createElement("script");
          script.src = `https://api-maps.yandex.ru/2.1/?apikey=${apikey}&load=package.standard&lang=ru_RU`;
          script.async = true;

          script.onload = () => {
            mapContainer.innerHTML = "";
            ymaps.load().then((maps) => {
              const myMap = new maps.Map(mapContainer, {
                center: mapCoordinates,
                zoom: mapZoom,
                controls: [],
              });
              const myPlacemark = new maps.Placemark(
                mapCoordinates,
                {
                  hintContent: hintContent,
                  balloonContent: balloonContent,
                },
                {
                  iconLayout: 'default#imageWithContent',
                  iconImageHref: iconImageHref,
                  iconImageSize: [48, 48],
                  iconImageOffset: [-24, -48],
                  hideIconOnBalloonOpen: false,
                },
              );
              myMap.geoObjects.add(myPlacemark);
              mapLoader.style.display = "none";
            });
          };
          document.body.appendChild(script);
        }
      }

      window.addEventListener("mouseover", () => {
        if (!mapContainer.classList.contains("ya-map_loaded")) {
          mapContainer.classList.add("ya-map_loaded");
          yaLoadMap();
        }
      });
    });
});
