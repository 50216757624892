function sectionFirstAnimation(selector = ".section") {
  if (document.querySelector(selector)) {
    document.querySelectorAll(selector).forEach(section => {
      sectionTL = gsap.timeline();

      sectionTL.fromTo(
        section,
        { opacity: 0, },
        { opacity: 1, duration: 0.6, ease: "cubicDefault" },
      );

      if (section.querySelector(".section__title")) {
        sectionTL.fromTo(
          section.querySelector(".section__title"),
          { opacity: 0, y: 10 },
          { opacity: 1, y: 0, duration: 0.4, ease: "cubicDefault" },
          "-=0.5"
        );
      }

      if (section.querySelector(".section__navigation>.button.button_icon-right")) {
        sectionTL.fromTo(
          section.querySelector(".section__navigation>.button.button_icon-right"),
          { opacity: 0, x: -10 },
          { opacity: 1, x: 0, duration: 0.4, ease: "cubicDefault" },
          "-=0.4"
        )
      }

      if (section.querySelector(".section__navigation>.section__swiper-navigation")) {
        sectionTL.fromTo(
          section.querySelector(".section__navigation>.section__swiper-navigation"),
          { opacity: 0, x: 10 },
          { opacity: 1, x: 0, duration: 0.4, ease: "cubicDefault" },
          "-=0.4"
        )
      }

      ScrollTrigger.create({
        animation: sectionTL,
        trigger: section,
        start: "top 80%",
        end: "bottom 100%",
        scrub: false,
      });

    });
  }
}
