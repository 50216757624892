document.addEventListener("DOMContentLoaded", () => {
  function collapseButtonTextReplace(button, buttonTextOld, buttonTextNew) {
    button.innerHTML = buttonTextNew;
    button.setAttribute("data-collapse-text", buttonTextOld);
  }

  document.querySelectorAll("[data-hidden-text]").forEach(button => {
    button.addEventListener("click", event => {
      event.preventDefault();
      const hiddenTextContainer = button.closest(".hidden-text"),
        hiddenTextHeight = hiddenTextContainer.querySelector(".hidden-text__text").clientHeight,
        buttonText = button.querySelector(".button__text"),
        buttonTextOld = buttonText.innerHTML,
        buttonTextNew = buttonText.getAttribute("data-collapse-text");

      if (hiddenTextContainer.classList.contains("hidden-text_show")) {
        hiddenTextContainer.classList.remove("hidden-text_show");
        collapseButtonTextReplace(buttonText, buttonTextOld, buttonTextNew);
        button.classList.remove("button_ignore");
      } else {
        hiddenTextContainer.style.cssText = `--hidden-text-height: ${hiddenTextHeight / 16}rem`;
        hiddenTextContainer.classList.add("hidden-text_show");
        collapseButtonTextReplace(buttonText, buttonTextOld, buttonTextNew);
        button.classList.add("button_ignore");
      }

    });
  });
});
