function contactsPageFirstAnimation(selector = ".contacts-page") {
  if (document.querySelector(selector)) {
    document.querySelectorAll(selector).forEach(section => {
      sectionTL = gsap.timeline();

      function originContactsAnimation(section) {
        section.querySelectorAll(".contacts-social__item").forEach((card, index) => {
          const delay = !index ? null : "-=0.1";
          gsap.fromTo(
            card,
            { opacity: 0, y: 10 },
            { opacity: 1, y: 0, duration: 0.2, ease: "cubicDefault" },
            delay
          );
        });

        if (section.querySelector(".swiper-slide")) {
          section.querySelectorAll(".swiper-slide").forEach((card, index) => {
            const delay = !index ? null : "-=0.10";
            sectionTL.fromTo(
              card,
              { opacity: 0, y: 10 },
              { opacity: 1, y: 0, duration: 0.2, ease: "cubicDefault" },
              delay
            );
          });
        }

        if (section.querySelector(".contacts-page__map")) {
          section.querySelectorAll(".contacts-page__map").forEach(item => {
            gsap.fromTo(
              item,
              { opacity: 0, x: -15 },
              { opacity: 1, x: 0, duration: 0.5, delay: 0.3, ease: "cubicDefault" },
            );
          });
        }
      }

      originContactsAnimation(section);

      section.querySelectorAll(".tab__input").forEach(input => {
        input.addEventListener("change", () => {
          if (!!input.checked) {
            const tabSelector = input.closest("[data-mega-tab-path]").getAttribute("data-mega-tab-path");
            originContactsAnimation(document.querySelector("[data-mega-tab=\"" + tabSelector + "\"]"));
          }
        });
      });

    });
  }
}
