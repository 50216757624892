document.addEventListener("DOMContentLoaded", () => {
	if (document.querySelector("[data-select-input]")) {
		function closeAllSelect() {
			document.querySelectorAll("[data-select-input]").forEach(select => {
				select.querySelector(".select-input__checkbox").checked = false;
			});
		}
		document.querySelectorAll("[data-select-input]").forEach(select => {
			const selectCheckbox = select.querySelector(".select-input__checkbox"),
				selectCurrent = select.querySelector(".select-current"),
				selectCurrentText = select.querySelector(".select-current__text"),
				selectAnswer = select.querySelector(".select-input__input"),
				selectAnswerList = select.querySelectorAll(".select-answer__item");
			selectCurrent.addEventListener("click", event => {
				if (!selectCheckbox.disabled) {
					event.preventDefault();
					const checkboxIsChecked = selectCheckbox.checked;
					closeAllSelect();
					if (!checkboxIsChecked) {
						selectCheckbox.checked = true;
					}
				}
			});
			if (select.classList.contains("select-input_checkbox") || select.classList.contains("select-input_radio")) {
				selectAnswerList.forEach(answer => {
					answer.addEventListener("click", () => {
						var answerArray = [];
						var answerText = "";
						selectAnswerList.forEach(value => {
							const valueCheckbox = value.querySelector(".select-answer__input"),
								valueAnswer = valueCheckbox.closest(".select-answer__item").querySelector(".select-answer__text");
							if (valueCheckbox.checked) {
								answerArray.push(valueAnswer.innerHTML.trim());
							}
						});
						answerArray.forEach((answerItem, index) => {
							index != answerArray.length - 1 ? answerText += answerItem + ", " : answerText += answerItem;
						});
						selectCurrentText.innerHTML = answerText;
						selectAnswer.value = answerText;
						
						if (select.classList.contains("select-input_radio")) {
						    closeAllSelect();
						}
					});
				});
			} else {
			    selectAnswerList.forEach(answer => {
					answer.addEventListener("click", event => {
						if (answer.tagName.trim().toLowerCase() != "a") {
							event.preventDefault();
						}
						let answerText = answer.querySelector(".select-answer__text");
						answerText = answerText.innerHTML.trim();
						selectCurrentText.innerHTML = answerText;
						if (answer.getAttribute("data-value")) {
							selectAnswer.value = answer.getAttribute("data-value");
						} else {
							selectAnswer.value = answerText;
						}
						closeAllSelect();
					});
				});
			}
		});
		document.addEventListener("click", (event) => {
			if (!event.target.closest("[data-select-input]")) {
				closeAllSelect();
			}
		});
		
		document.addEventListener("scroll", (event) => {
		    closeAllSelect();
		});
	}
});