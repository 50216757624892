function catalogFirstAnimation(selector = ".catalog.section") {
  if (document.querySelector(selector)) {
    document.querySelectorAll(selector).forEach(section => {
      sectionTL = gsap.timeline();

      if (section.querySelector(".catalog-card")) {
        section.querySelectorAll(".catalog-card").forEach((card, index) => {
          const delay = !index ? null : "-=0.13";
          sectionTL.fromTo(
            card,
            { opacity: 0, y: -10 },
            { opacity: 1, y: 0, duration: 0.2, ease: "cubicDefault" },
            delay
          )
        });
      }

      ScrollTrigger.create({
        animation: sectionTL,
        trigger: section,
        start: "top 80%",
        end: "bottom 100%",
        scrub: false,
      });

    });
  }
}
